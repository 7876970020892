<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_project" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">{{ $t('Projet') }}</h2>
                <h1 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.titre"></span>
                </h1>
                <div class="infos" data-inview="fadeInUp" data-delay="400">
                    <div class="info">
                        <p class="title small">{{ $t('Client') }}</p>
                        <h3 class="title">{{ content.client }}</h3>
                    </div>
                    <div class="info">
                        <p class="title small">{{ $t('Secteur') }}</p>
                        <h3 class="title">{{ content.secteur }}</h3>
                    </div>
                    <div class="info">
                        <p class="title small">{{ $t('Emplacement') }}</p>
                        <h3 class="title">{{ content.emplacement }}</h3>
                    </div>
                </div>
            </div>
            <div class="main-carousel" data-inview="revealRight" data-delay="200">
                <div class="carousel-cell" v-for="(item, i) in content.images" :key="i">
                    <img :src="item.url" :alt="item.titre" />
                </div>
            </div>
            <a href="#next" v-scroll-to:-120
                ><svg
                    fill="none"
                    class="arrow"
                    viewBox="0 0 13 26"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m11.6412 17.9322-4.17083 4.2832v-22.2154h-1.94081v22.2154l-4.17082-4.2832-1.35874 1.395 6.5 6.6728 6.5-6.6728z"
                    /></svg
            ></a>
        </section>

        <section class="txt_row small-padding-bottom" id="next" v-if="pageIsLoaded">
            <div class="row" data-inview="fadeInUp" data-delay="200">
                <h4 class="title medium">{{ $t('Description du site') }}</h4>
                <p class="regular-text">
                    <span v-html="content.texteSimple1"></span>
                </p>
            </div>
            <div class="row" data-inview="fadeInUp" data-delay="200">
                <h4 class="title medium">{{ $t('Portée des travaux') }}</h4>
                <div class="wrap">
                    <div v-for="(item, i) in content.listeInformations1" :key="i">
                        <p class="regular-text bold">{{ item.titre }}</p>
                        <p class="regular-text">
                            <span v-html="item.texte"></span>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <div class="testimonial-slider" v-if="pageIsLoaded && globals.footer.temoignages && globals.footer.temoignages[0].texte">
            <div class="carousel-cell" v-for="(item, i) in globals.footer.temoignages" :key="i">
                <section class="testimonial_wrap">
                    <img
                        src="@/assets/img/quote-icon.svg"
                        alt="Quote icon"
                        class="quote"
                        data-inview="fadeInUp"
                        data-delay="200"
                    />
                    <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                        <span v-html="item.texte"></span>
                    </p>
                    <div class="name" data-inview="fadeInUp" data-delay="400">
                        <h2 class="title small">{{item.surtitre}}</h2>
                        <p class="regular-text">{{item.titre}}</p>
                    </div>
                </section>
            </div>
        </div>

        <section class="project_listing" v-if="pageIsLoaded && realisationsChunks.length">
            <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
                <h2 class="title home small" data-inview="fadeInUp" data-delay="200">
                    {{ content.entete1.surtitre }}
                </h2>
                <h3 class="title home medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.entete1.titre"></span>
                </h3>
            </div>
            <div class="row">
                <router-link
                    :to="item.page.jsonUrl"
                    class="project"
                    data-inview="fadeInUp"
                    :data-delay="2 * 1 + '00'"
                    v-for="(item, i) in realisationsChunks[0]"
                    :key="i"
                >
                    <div class="img">
                        <div class="more">
                            <p class="regular-text">{{ $t('En savoir plus') }}</p>
                        </div>
                        <img
                            :src="item.page.imageSimple1.url"
                            :alt="item.page.imageSimple1.titre"
                        />
                    </div>
                    <div class="data">
                        <p class="title">{{ item.page.titre }}</p>
                    </div>
                </router-link>
            </div>
            <div class="row">
                <router-link
                    :to="item.page.jsonUrl"
                    class="project"
                    data-inview="fadeInUp"
                    :data-delay="2 * 1 + '00'"
                    v-for="(item, i) in realisationsChunks[1]"
                    :key="i"
                >
                    <div class="img">
                        <div class="more">
                            <p class="regular-text">{{ $t('En savoir plus') }}</p>
                        </div>
                        <img
                            :src="item.page.imageSimple1.url"
                            :alt="item.page.imageSimple1.titre"
                        />
                    </div>
                    <div class="data">
                        <p class="title">{{ item.page.titre }}</p>
                    </div>
                </router-link>
            </div>
            <div class="cta_wrap" data-inview="fadeInUp" data-delay="200">
                <router-link :to="{ name: 'Realisations-' + $route.meta.lang }" class="cta">
                    <p class="regular-text">{{ $t('Découvrez nos réalisations') }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import GLightbox from 'glightbox'
import Flickity from 'flickity'

export default {
    data() {
        return {
            projectCarousel: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.projectCarousel = new Flickity(
                            document.querySelector('.main-carousel'),
                            {
                                wrapAround: true,
                                imagesLoaded: true,
                                prevNextButtons: true,
                                pageDots: false,
                            }
                        )

                        setTimeout(() => {
                            this.projectCarousel.resize()
                        }, 1755)

                        this.projectCarousel = new Flickity(
                            document.querySelector('.testimonial-slider'),
                            {
                                wrapAround: true,
                                imagesLoaded: true,
                                prevNextButtons: true,
                                pageDots: false,
                            }
                        )

                        setTimeout(() => {
                            this.projectCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        realisationsChunks() {
            if (!this.pageIsLoaded) {
                return [[], []]
            }
            // Take 4 items from realisationsEntry data array
            let realisationsArray = this.globals.realisationsEntry.data.slice(0, 4)

            realisationsArray = realisationsArray.filter((item) => {
                return item.page.jsonUrl !== this.content.jsonUrl
            })

            // Split the realisations array in two chunks of 2
            return realisationsArray.reduce((acc, item, index) => {
                if (index % 2 === 0) {
                    acc.push([item])
                } else {
                    acc[acc.length - 1].push(item)
                }
                return acc
            }, [])
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
